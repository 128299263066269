import React from "react";

const Footer = ({title}) => {

    return (
        <footer className="row justify-content-center text-center mt-5">
            <small>
                © {title}.nl {new Date().getFullYear()}<br/>
                Alle rechten voorbehouden
            </small>
        </footer>
    );
};

export default Footer;
