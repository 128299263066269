import React from "react";
import {Link} from "gatsby";
import Logo from "../assets/images/logo.png";

const Header = ({title}) => {

    return (
        <header className="container-lg">
            <div className="row justify-content-between my-4">
                <div className="col-4 col-sm-3 col-md-2 col-lg-2">
                    <h1>
                        <Link to="/"><img src={Logo} alt={title + `logo`}/></Link>
                    </h1>
                </div>
            </div>
        </header>
    );
};

export default Header;
