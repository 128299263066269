import * as React from "react"
import Footer from "./footer";
import Header from "./header";

const Layout = ({location, title, isHomePage, children}) => {

    return (
        <div className="container-fluid" data-is-root-path={isHomePage}>
            <Header title={title}/>
            <main>{children}</main>
            <Footer title={title}/>
        </div>
    )
}

export default Layout
